$(document).ready(function () {
    // function setPoster() {
    //     let videoPoster = $(".BN-block--video").attr("data-poster");
    //     let MobileVideoPoster = $(".BN-block--video").attr("data-mposter");
    //     if ($(window).width() < 768) {
    //         $(".BN-block--video video").attr("poster", MobileVideoPoster);
    //     } else {
    //         $(".BN-block--video video").attr("poster", videoPoster);
    //     }
    // }
    // //set poster by default
    // setPoster();
    // //set poster on resize
    // $(window).resize(function () {
    //     console.log("resize");
    //     setPoster();
    // });

    //function to trigger video after play button is clicked
    const video = document.querySelector("video");

    video.addEventListener("play", (event) => {
        $(".BN-block--video--overlay").hide();
    });

    video.addEventListener("pause", (event) => {
        $(".BN-block--video--overlay").show();
    });
});
